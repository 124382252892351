
/* page structure */

html,
body {
	height: 100%;
}

body {
	padding-top: 50px;
}

.main {
	min-height: 100%;
}

[draggable] {
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	user-select: none;
	/* Required to make elements draggable in old WebKit */
	-khtml-user-drag: element;
	-webkit-user-drag: element;
}

.page-body {
	height: 100%;
}

.page-body .main {
	padding-bottom: 70px;
}

body > pre.cake-error {
	margin: 15px 30px 0 19%;
}

/* bootstrap overrides */

blockquote {
	border-color: #9CF;
}

.form-group.date {
	display: inline-block;
}

.form-horizontal .form-group.date {
	display: block;
}

.form-group.date label {
	display: block;
}
.form-group.date select {
	display: inline-block;
	width: auto;
}

.form-horizontal label {
	margin: 7px 0;
}

.form-horizontal .checkbox {
	padding-top: 0;
	margin-bottom: 15px;
}

.form-horizontal input[type="checkbox"],
.form-horizontal .checkbox input[type="checkbox"] {
	margin-left: -10px;
}

.form-horizontal input[type="checkbox"] + label {
	margin: 0;
	padding-top: 0;
	padding-left: 15px;
}

.radio {
	margin-top: 0;
}
tr.radio {
	display: table-row;
}

.radio input[type="radio"] {
	margin-left: 0;
}

.radio legend {
	font-size: 14px;
	border: 0;
	margin-bottom: 7px;
	font-weight: bold;
}

.pagination {
	margin-top: 0;
}

.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-striped > tbody > tr:nth-child(odd) > th,
th {
	background-color: #444;
	border-color: #444;
	color: #CCC;
}

.table tbody + tbody {
	border-top: 0;
}

th a:link,
th a:visited {
	color: #FFF;
}

address {
	margin-bottom: 0;
}

.modal-footer {
	padding: 14px 15px 15px;
}

.modal-loading {
	text-align: center;
	padding: 75px 0;
}

.dropdown-toggle {
	height: 34px;
}

.btn-sm.dropdown-toggle {
	height: 30px;
}

.toggle-right {
	left: auto;
	right: 0;
}

.collapse {
	display: none;
}

.dl-horizontal dt {
	text-align: left;
}

.dl-horizontal dd {
	text-align: right;
}

time[title] {
	cursor: help;
	border-bottom: 1px dotted #999;
}

.nav-tabs {
	margin-bottom: 20px;
	padding: 0 10px;
}

.btn-sm .glyphicon {
	top: 2px;
	right: 1px;
}

.btn-lg .glyphicon {
	top: 3px;
	right: 2px;
}

.label-snooze {
	background-color: #AB33B7;
}

.table-responsive-mod > .table > tbody > tr > td,
.table-responsive-mod > .table > tbody > tr > th,
.table-responsive-mod > .table > tfoot > tr > td,
.table-responsive-mod > .table > tfoot > tr > th,
.table-responsive-mod > .table > thead > tr > td,
.table-responsive-mod > .table > thead > tr > th {
	white-space: normal;
}


/* main menu */

.navbar-inverse {
	background-color: #333;
	border: none;
}

.navbar-fixed-top {
	border: 0;
}

.navbar-toggle {
	padding: 12px 20px 14px;
	margin: 0 -15px;
	border: 0;
	border-radius: 0;
}

.navbar-inverse .navbar-toggle {
	background: #333;
}

.navbar-inverse .navbar-toggle.active {
	background: #f5f5f5;
	color: #333;
}

.navbar-inverse .navbar-toggle .glyphicon {
	color: #FFF;
	font-size: 24px;
}

.navbar-inverse .navbar-toggle.active .glyphicon {
	color: #333;
	font-size: 24px;
}


.navbar-brand {
	display: block;
	float: none;
	text-align: center;
}

.navbar-brand {
	font-weight: bold;
	font-size: 20px;
	margin: 0 -15px;
}

.navbar-inverse .navbar-brand {
	color: rgba(255, 255, 255, 0.7);
}

.sidebar-toggle {
	float: left;
}

.sidebar {
	display: none;
	position: fixed;
	background: #f5f5f5;
	z-index: 1000;
	top: 50px;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-y: auto;
	overflow-x: hidden;
}

.nav-sidebar {
	margin-right: -21px;
	margin-bottom: 20px;
	margin-left: -20px;
}

.nav-sidebar > li > a {
	padding-right: 20px;
	padding-left: 20px;
}
.nav-sidebar > .active > a {
	color: #fff;
	background-color: #428bca;
}

.nav-sidebar.panel-group {
	border-top: 1px solid #EEE;
}

.nav-sidebar.panel-group .panel + .panel {
	margin: 0;
}

.nav-sidebar .panel {
	border: 0;
	background: none;
	margin: 0;
}

.nav-sidebar .panel-collapse {
	background: #EEE;
}

.nav-sidebar .panel li a:link,
.nav-sidebar .panel li a:visited {
	display: block;
	padding: 5px 20px 5px 30px;
	text-decoration: none;
}

.nav-sidebar li a:hover,
.nav-sidebar li a:active {
	background: #E2E2E2;
}

.nav-sidebar .glyphicon {
	margin-right: 5px;
}

.nav-sidebar .caret {
	position: absolute;
	right: 20px;
	top: 18px;
}


/* body sections */

.page-head,
.page-title {
	border-bottom: 1px solid #DDD;
	margin-bottom: 15px;
}

.page-title h1 {
	margin: 18px 0;
}

.page-title form {
	margin: 15px 0;
}

.page-title .actions {
	padding-top: 15px;
}

.page-head .actions {
	padding: 15px 0 10px;
	font-size: 0;
}

.page-head .actions .btn {
	margin-top: 5px;
	margin-left: 5px;
}

.page-head .meta {
	text-align: right;
}

.page-head .meta h1 {
	margin: 15px 0;
}

.page-head .meta h3 {
	margin: 10px 0;
}

.page-head .meta h4 {
	margin: 5px 0;
}


/* footer */

#footer {
	font-size: 12px;
	color: #777;
	position: absolute;
	bottom: 15px;
}

/* client search */

#ClientSearchForm {
	position: relative;
	display: block;
	margin-left: -15px;
	margin-right: -15px;
	z-index: 100;
}

#ClientSearchForm input {
	display: block;
	width: 100%;
}

#ClientSearchResults {
	position: fixed;
	width: 100%;
	top: 40px;
	z-index: 1030;
	display: none;
	bottom: 70px;
}

#ClientSearchResults .results {
	max-height: 100%;
	overflow-y: auto;
	border-top-left-radius: 4px;
}

#ClientSearchResults .footer {
	display: flex;
	height: 50px;
}

#ClientSearchResults .footer .list-group-item {
	width: 50%;
	margin-top: -1px;
	margin-bottom: 0;
}

#ClientSearchResults .all-results {
	border-bottom-left-radius: 4px;
}

#ClientSearchResults .close-results {
	text-align: right;
	border-bottom-right-radius: 4px;
}

#ClientSearchResults a {
	border-radius: 0;
}

#ClientSearchResults .footer h4 {
	margin: 5px;
}

#MobileSearch {
	background-color: #F5F5F5;
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	top: 50px;
	z-index: 1050;
	overflow: auto;
	visibility: visible;
}

#MobileSearch form {
	padding: 15px 15px 0;
}

#MobileSearch form .glyphicon {
	height: 20px;
}

#MobileSearch .list-group-item {
	border-radius: 0;
	border-left: 0;
	border-right: 0;
	background: #F5F5F5;
}

#MobileSearch .list-group-item:hover,
#MobileSearch .list-group-item:active,
#MobileSearch .list-group-item:focus {
	background: #FFF;
}

.loading {
	background: url('../img/loading-white.gif') no-repeat center center #FFF;
}

/* login page */

.login-header {
	text-align: center;
}

.login-form {
	max-width: 300px;
	margin: 20px auto;
}

.login-form .submit {
	margin-top: 5px;
}


/* ticket tags */

.tagit-hidden-field {
	display: none !important;
}

.tagit {
	background-color: #ffffff;
	border-radius: 4px;
	border: 1px solid #cccccc;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	color: #333;
	display: block;
	font-size: 18px;
	list-style: none;
	line-height: 32px;
	margin: 0;
	padding: 5px 6px 0;
	vertical-align: middle;
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.tagit:focus {
	border-color: #66afe9;
	outline: 0;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.tagit-choice,
.tagit-new {
	display: inline-block;
	margin-bottom: 5px;
}

.tagit-choice {
	background: #BDF;
	border-radius: 4px;
	border: 1px solid #CCC;
	margin-right: 5px;
	padding-left: 4px;
	padding-right: 6px;
}

a.tagit-close,
a.tagit-close:link,
a.tagit-close:visited,
a.tagit-close:hover,
a.tagit-close:active {
	text-decoration: none;
	font-weight: bold;
	color: #333;
	cursor: pointer;
}

.tagit-label {
	padding: 0 5px;
}

.tagit-new input {
	margin: 0;
	padding: 1px;
	border: none;
	outline: 0;
	max-width: 90%;
}

.ui-autocomplete {
	border: 1px solid #CCC;
	list-style: none;
	background: #FFF;
	padding: 5px;
	margin: 0 1px;
	width: auto;
	border-radius: 2px;
	cursor: default;
	position: absolute;
}

.ui-menu .ui-menu-item {
	margin: 0;
	padding: 0;
	width: 100%;
	font-size: 18px;
}

.ui-menu .ui-menu-item a {
	text-decoration: none;
	display: block;
	padding: 2px .4em;
	line-height: 1.5;
	min-height: 0;
	font-weight: normal;
	border-radius: 3px;
}

.ui-menu .ui-menu-item a:hover,
.ui-menu .ui-menu-item a:active,
.ui-state-focus {
	background-color: #428bca;
	color: #FFF;
}

.ui-helper-hidden-accessible {
	display: none;
}

.tagit .new-tag {
	background: #BFB;
}

#InvoiceFindForm .form-control,
.ticket-filter .tagit {
	border-radius: 0;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.ticket-filter .tagit .new-tag {
	background: #FBB;
}

#available-tags {
	margin-top: 10px;
}


/* contact info element */

.contact-info {
	margin-bottom: 20px;
}


/* icons */

.icon {
	padding: 4px;
	margin: 3px 0;
	white-space: nowrap;
}

.icon.delete,
.icon.delete:link,
.icon.delete:visited {
	color: #B00;
}
.icon.delete:hover,
.icon.delete:active {
	color: #800;
}

.icon.create,
.icon.create:link,
.icon.create:visited {
	color: #0A3;
}
.icon.create:hover,
.icon.create:active {
	color: #071;
}


/* global */

.markdown-preview {
	margin: 5px 0;
	display: block;
	padding: 5px;
	background: #EEE;
	border-radius: 5px;
}

.error-message {
	color: #A00;
}

.error input,
.error textarea,
.error input:focus {
	border-color: #953b39;
	-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075),0 0 6px #d59392;
	box-shadow: inset 0 1px 1px rgba(0,0,0,0.075),0 0 6px #d59392;
}

.flash.alert {
	margin: 15px 0 -5px;
	padding: 7px 15px;
}

.alert .close {
	opacity: 1;
	right: 0;
}

.actions,
.money-col {
	text-align: right;
}

td.money-col {
	white-space: nowrap;
}

.actions > * {
	text-align: left;
}

.kv-list {
	border-top: 1px solid #DDD;
}

.kv-list .kv-pair {
	border-bottom: 1px solid #DDD;
	padding: 7px 3px;
}

.kv-list .key {
	font-weight: bold;
	float: left;
	margin-right: 5px;
}

.kv-list .value {
	float: right;
	text-align: right;
}

.kv-list .value.code,
.kv-list .value.ip_address {
	font-family: monospace;
}


/* client profile */

#ClientProfileMenu {
	margin-bottom: 20px;
}

header.client-profile .name {
	text-align: center;
}

header.client-profile .name h1 {
	margin-bottom: 20px;
}

header.client-profile h3 {
	font-size: 20px;
}

header.client-profile .number {
	text-align: right;
}

header.client-profile h2 {
	margin-top: 0;
}

.client-info .kv-list {
	font-size: 16px;
}

#ClientProfileMenu + header > h2 {
	margin-bottom: 20px;
}

.recent-activity {
	font-size: 16px;
	line-height: 1.5;
}


/* offres view */

.offre-prices {
	margin: 0;
}

.offre-prices .col-xs-6 {
	padding: 0;
	padding-right:  3px;
}

.offre-prices h2,
.offre-prices h3,
.offre-prices h4 {
	margin: 0;
}

.offre-prices .prix-details {
	font-size: 12px;
	display: inline-block;
	line-height: 110%;
}

.offre-description {
	margin: 5px 0;
}

.offre-image {
	text-align: center;
	padding: 20px 10px;
}

.offre-image img {
	max-width: 100%;
}


/* invoice view */

#pdf-view-wrap {
	position: absolute;
	width: 100%;
	bottom: 0;
	margin-left: -15px;
	top: 133px;
	z-index: 2;
}

#pdf-view-wrap object,
.pdf-frame {
	width: 100%;
	height: 100%;
}

.pdf-frame {
	background: #CCC;
	overflow: auto;
	padding: 20px 0 10px;
}

.pdf-frame .canvas-spacer {
	display: block;
	text-align: center;
}

.pdf-frame canvas {
	box-shadow: 0 0 10px #999;
	display: inline-block;
	margin: 0 20px 15px;
}

.invoice-view {
	border: none;
}


/* ticket view */

.header-prefix {
	margin: 20px 0 -15px;
	font-weight: bold;
}

.ticket .header-prefix .label {
	display: inline-block;
	font-size: 14px;
	margin-right: 4px;
}

#TicketMessageTitle {
	display: inline-block;
}

.ticket-message-panel {
	margin: 10px 0px;
}

.ticket-sidebar h2,
.ticket-sidebar h3 {
	margin-top: 10px;
}

.ticket-sidebar hr {
	margin-top: 10px;
}

/* ticket todo list */

.ticket-header-button {
	margin : 0px 5px;
}


/* ticket list */

.ticket-list-meta {
	margin-bottom: 20px;
	display: flex;
	flex-flow: wrap;
}

.ticket-list-meta .ticket-count {
	padding-left: 20px;
	text-align: right;
	flex-grow: 1;
}

.ticket-sorting .asc,
.ticket-sorting .desc {
	font-weight: bold;
}

.ticket-sorting .asc:before {
	content: '\2B07 ';
}

.ticket-sorting .desc:before {
	content: '\2B06 ';
}

.ticket-sorting a {
	white-space: nowrap;
}

.ticket-preview {
	display: flex;
	margin-bottom: 20px;
    border-radius: .25em;
	background-color: #fafafa;
	box-shadow:
		0 2px 1px -1px rgba(0,0,0,.2),
		0 1px 1px 0 rgba(0,0,0,.14),
		0 1px 3px 0 rgba(0,0,0,.12);
}

.ticket-preview .ticket-status {
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	flex: 0 0 110px;
	background-color: #777;
	text-transform: uppercase;
	color: #fff;
	font-weight: bold;
	font-size: 12px;
}

@media (max-width: 768px) {
	.ticket-preview .ticket-status {
		width: 50px;
		flex: 0 0 50px;
	}

	.ticket-preview .ticket-status span {
		transform: rotate(-90deg);
		display: block;
		max-height: 32px;
		line-height: 16px;
		position: absolute;
		max-width: 100px;
	}
}

.ticket-preview .ticket-info {
	padding: 7px 10px;
	width: calc(100% - 110px);
	flex: 0 0 calc(100% - 110px);
}

@media (max-width: 768px) {
	.ticket-preview .ticket-info {
		width: calc(100% - 50px);
		flex: 0 0 calc(100% - 50px);
	}
}

.ticket-preview .ticket-info .context {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.ticket-preview .ticket-info .meta {
	color: #777;
}

.ticket-preview .ticket-info .meta > span + span:before {
	content: " • ";
}

.ticket-preview .ticket-info .title {
	margin: 7px 0;
}

.ticket-preview .ticket-status-default {
	background-color: #777;
}

.ticket-preview .ticket-status-danger {
  background-color: #d9534f;
}

.ticket-preview .ticket-status-warning {
  background-color: #f0ad4e;
}

.ticket-preview .ticket-status-info {
  background-color: #5bc0de;
}

.ticket-preview .ticket-status-primary {
  background-color: #337ab7;
}

.ticket-preview .ticket-status-success {
  background-color: #5cb85c;
}

.ticket-preview .ticket-status-snooze {
	background-color: #ab33b7;
}


/* invoices index */

.invoices .deleted {
	text-decoration: line-through;
}

.invoices td.actions {
	border-right: none;
	padding: 9px 7px 0;
	width: 28px;
	text-align: center;
}

.invoices td.actions + td.actions {
	border-left: none;
	padding-left: 0;
}

.invoices td.actions:last-child {
	border: 1px solid #ddd;
}

.invoices td.actions a.offline:link,
.invoices td.actions a.offline:visited {
	color: #777;
}

.invoices td.actions a.offline:hover,
.invoices td.actions a.offline:active {
	color: #555;
}


/* client form */

#ClientPhones .table {
	margin-bottom: 10px;
}

#ClientPhones td {
	border: none;
	padding: 5px 0;
}

#ClientPhones td + td {
	padding-left: 5px;
}

#ClientPhones .delete {
	display: block;
	padding: 6px 4px;
}


/* contract forms */

#MandateReference {
	font-family: monospace;
}

.new-mandate .col-sm-3 {
	padding-right: 0;
}

table.contact-elements th {
	background: #FFF;
	color: #333;
}

table.contact-elements th,
table.contact-elements td {
	padding: 3px;
}

table.contact-elements .medium {
	width: 250px;
}

table.contact-elements .numeric {
	width: 125px;
	text-align: right;
}

table.contact-elements .medium input,
table.contact-elements .medium select {
	min-width: 200px;
}

table.contact-elements .numeric input {
	text-align: right;
	min-width: 100px;
}

table.contact-elements textarea {
	min-width: 250px;
}

table.contact-elements .actions {
	width: 34px;
}


/* invoice forms */

.invoice-header {
	border-bottom: 1px solid #dddddd;
}

.invoice-header .actions {
	margin: 15px 0;
}

.invoice-body tr h4 {
	font-weight: bold;
	margin: 3px 0;
}

.invoice-body tr h2 {
	margin: 0;
}

.invoice-details-col{
	min-width: 150px;
}

.invoice-money-col {
	width: 100px;
}

.invoice-money-col input {
	text-align: right;
}

.invoice-element-type-col {
	width: 150px;
}

#InvoiceElements .ui-sortable-helper {
	background: #FFD;
}

#InvoiceElements .sort-icon {
	vertical-align: middle;
	color: #777;
	cursor: ns-resize;
	width: 26px;
}

#InvoiceElements .sort-icon:hover,
#InvoiceElements .sort-icon:active {
	color: #333;
}

#InvoiceElements tr {
	background: #FFF;
	width: 100%;
}

#InvoiceElements td {
	padding-right: 0;
}

#InvoiceElements select {
	min-width: 100px;
	padding : 6px 0px;
}

#InvoiceElements textarea {
	min-width: 80px;
	padding : 6px;
	resize: vertical;
}

#InvoiceElements .actions {
	width: 28px;
	padding-left: 6px;
}

#InvoiceElements .error-message {
	min-width: 132px;
}

#InvoiceElements a[href="#/remove-invoice-element"] {
	margin: 0;
	padding: 9px 0;
	display: block;
}


/* panels */

.panel-data {
	margin: 0 0 20px;
}

.panel-data > .panel-heading > .actions {
	margin: -5px -5px -5px 5px;
}

.panel-data > .panel-heading > .actions .icon .glyphicon {
	margin: 6px 3px;
}

.panel-data .panel-title {
	font-size: 18px;
	margin: 0px -5px;
}

.panel-data .panel-footer.meta {
	text-align: right;
	font-style: italic;
	padding: 6px 15px;
}

.panel-data .panel-footer.toggle {
	padding: 0;
}

.panel-data .panel-footer.toggle a {
	display: block;
	font-size: 16px;
	padding: 6px 15px;
	text-align: center;
}

.panel-data .panel-footer.toggle a:hover,
.panel-data .panel-footer.toggle a:active {
	background: #EEE;
}

.panel-info .panel-footer {
	color: #31708f;
	background-color: #d9edf7;
	border-color: #bce8f1;
}


.contract,
.datasheet {
	margin-top: 20px;
}

.contract h4 {
	font-weight: bold;
}

.contract .basics {
	margin-bottom: 25px;
}

.contract .basics .label {
	font-size: 14px;
	display: inline-block;
}

.contract .invoice-actions .btn {
	margin-bottom: 5px;
}

.contract table {
	border-top: 1px solid #ddd;
}

.contract table > thead > tr > th,
.contract .table > tbody > tr > th,
.contract .table > tfoot > tr > th,
.contract .table > thead > tr > td,
.contract .table > tbody > tr > td,
.contract .table > tfoot > tr > td {
	background: none;
	border-top: none;
	border-bottom: 1px solid #ddd;
	color: #333;
}

.contract .table .pre-header th {
	border-bottom: 0;
	padding-bottom: 0;
}

.datasheet .kv-list {
	border-top: none;
}

.datasheet .kv-pair {
	display: inline-block;
	width: 100%;
}

.datasheet-diff table {
	border-bottom: 1px solid #ddd;
}

.datasheet-diff table td {
	width: 33%;
}

#DatasheetClientsAddForm .code,
#DatasheetClientsEditForm .code {
	font-family: monospace;
}

.contract .status {
	margin: 2px 5px 0 -5px;
	font-size: 17px;
}

.contract-providers .dl-horizontal dt {
	width: auto;
}

/** client profile documents **/

.documents {
	-webkit-columns: 3 350px;
	-moz-columns: 3 350px;
	columns: 3 350px;
	line-height: 1.2;
}

.documents .wrap {
	display: inline-block;
	width: 100%;
}

.document {
	font-size: 16px;
	clear: both;
	display: table;
	width: 100%;
	padding: 7px 3px;
	border-radius: 4px;
}

.document:hover {
	background: #F1F1F1;
}

.document .doc-row {
	display: table-row;
}

.document .cell {
	display: table-cell;
	vertical-align: middle;
}

.document .file-icon {
	width: 35px;
}

.document .file-info {
	vertical-align: top;
}

.document .cell.delete {
	width: 25px;
}

.document .created {
	display: block;
	font-size: 12px;
	color: #666;
}

.document-preview .modal-body {
	padding: 0;
	overflow-y: auto;
	white-space: nowrap;
	text-align: center;
}

.document-preview .modal-body .helper {
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

.document-preview .modal-body img {
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
	padding: 15px;
	vertical-align: middle;
}

.document-preview .modal-body object {
	width: 100%;
	height: 100%;
}

.document-preview .modal-body pre {
	margin: 15px;
	text-align: left;
}

.document-preview .modal-body iframe {
	border: 0;
	width: 100%;
}

.document-preview .modal-footer form {
	margin: 0;
}

/* espace client modal */

.espace-accounts .modal-body.table {
	margin: -1px 0 0;
}

.espace-accounts .table td {
	padding-left: 15px;
}

.espace-accounts .table td.actions {
	padding-left: 0;
	padding-right: 15px;
}

.espace-accounts .modal-footer {
	margin-top: 0;
}

.espace-accounts .add-account {
	height: 75px;
}

.espace-accounts .actions {
	width: 75px;
}

/* datepicker */


.datebox[readonly] {
	cursor: pointer;
	background-color: #F8F8F8;
}

.datepicker th {
	background: none;
	color: #333;
}

.datepicker table tr td.today,
.datepicker table tr td.today.disabled {
	background: #EE7;
	font-weight: bold;
}

.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today.disabled:hover:hover {
	color: #333;
	background: #EEE;
}

.modal .datepicker-inline,
.modal .datepicker-inline table {
	width: 100%;
}

.checkbox-bugfix .checkbox {
	margin-left: 20px;
}

/*Small smartphones*/
@media (max-width: 400px) {
	.num-client:before {
		content: "\A0";
	}

	.panel-data .panel-title {
		margin: 30px -5px 0px -5px;
	}

	.status-title {
		margin: 0 !important; 
	}
	
	#search-title{
		margin: 0;
	}
	
	#title-contracts{
		padding-top: 40px;
	}

}

/*Devices larger than small smartphones*/
@media (min-width: 345px) {
	.suppress-button:after {
		content: "imer";
	}
}

@media (max-width: 768px) {
	.datepicker-dropdown {
		position: fixed !important;
		top: auto !important;
		left: 0 !important;
		right: 0 !important;
		bottom: 0 !important;
		z-index: 1030 !important;
		border: none !important;
		padding: 0 !important;
		box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.2) !important;
		border-radius: 0 !important;
	}

	.datepicker-dropdown:before,
	.datepicker-dropdown:after {
		display: none !important;
	}

	.datepicker-dropdown table {
		width: 100%;
		margin-top: 5px;
	}
}

/* unsorted */

#InvoiceFindForm .input-group-btn {
	vertical-align: top;
}

.new-message {
	color: #999;
	height: 65px;
	margin-bottom: 20px;
	cursor: pointer;
}

.panel-form .panel-heading,
.panel-form .panel-body,
.panel-form .panel-footer {
	padding: 5px 7px;
}

.panel-form .markdown-info {
	line-height: 34px;
}

#InvoiceIndexForm button {
	padding: 13px 16px;
}
.datebox.input-lg {
	width: 170px;
}


/* typeahead.js */

.twitter-typeahead {
	width: 100%;
}

.typeahead,
.tt-query,
.tt-hint {
	background-color: #fff;
	background-image: none;
	border-radius: 4px;
	border: 1px solid #ccc;
	color: #555;
	font-size: 14px;
	line-height: 1.42857143;
	outline: none;
	padding: 6px 12px;
	width: 100%;
}

.tt-query {
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
	color: #AAA;
}

.tt-menu {
	width: 100%;
	padding: 8px 0;
	background-color: #fff;
	border: 1px solid #ccc;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	box-shadow: 0 5px 10px rgba(0,0,0,.2);
}

.tt-menu strong {
	color: #333;
}

.tt-menu strong.tt-highlight {
	color: #000;
}

.tt-menu .tt-suggestion.tt-cursor strong,
.tt-menu .tt-suggestion:hover strong {
	color: #fff;
}

.tt-suggestion {
	padding: 3px 20px;
}

.tt-suggestion.tt-cursor,
.tt-suggestion:hover {
	color: #fff;
	background-color: #0097cf;
}

.tt-suggestion p {
	margin: 0;
}


.search-results {
	width: 100%;
}

.search-results .result-list {
	display: table;
	width: 100%;
}

.search-results .result {
	display: table-row;
}

.search-results .result:nth-child(odd) {
	background: #F5F5F5;
}

.search-results .result > .cell {
	display: table-cell;
	padding: 5px;
}

.search-results .cell + .cell {
	padding-left: 50px;
}

.search-results .cell span {
	display: block;
}

.search-results .client-number {
	width: 150px;
}

.search-results .client-name,
.search-results .contract {
	width: 350px;
	min-width: 350px;
}

.search-results .load-more a {
	display: block;
	margin-top: 20px;
	padding: 10px;
	border-radius: 4px;
}

.search-results .load-more a:hover,
.search-results .load-more a:active {
	background: #eee;
}

h3 > .label {
	float: left;
	margin-right: 7px;
	font-size: 70%;
}

.chart {
	position: relative;
	width: 100%;
}

.chart:before {
	content: "";
	display: block;
	padding-top: 40%;
}

.chart canvas {
	position:  absolute;
	top: 0;
	width: 100% !important;
	height: 100% !important;
}

.legend-square {
	width: 12px;
	height: 12px;
	display: inline-block;
	margin: 0 3px;
}

.invoice-view h1 {
	font-size: 24px;
}


/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {

	.invoice-view h1 {
		font-size: 36px;
	}

	header.client-profile h3 {
		font-size: 24px;
	}

	header.client-profile h2 {
		margin: 0;
	}

	.navbar-header {
		text-align: left;
	}

	.nav-sidebar.panel-group {
		border-bottom: 0;
	}

	.main {
		padding-right: 30px;
		padding-left: 30px;
	}

	.sidebar {
		display: block;
		bottom: 0;
		padding: 20px;
		overflow-x: hidden;
		overflow-y: auto;
		border-right: 1px solid #eee;
	}

	#pdf-view-wrap {
		margin: 0 -30px;
	}

	.datasheet .kv-list {
		-webkit-column-count: 2;
		-webkit-column-gap: 10px;
		-moz-column-count: 2;
		-moz-column-gap: 10px;
		column-count: 2;
		column-gap: 10px;
	}

}

@media (max-width: 992px) {

	#SubscriptionInvoiceView .btn-lg {
		margin-bottom: 4px;
	}

}

@media (min-width: 992px) {

	#pdf-view-wrap {
		top: 75px;
	}

}

@media (min-width: 1200px) {

	header.client-profile h3 {
		line-height: 39px;
	}

	.datasheet .kv-list {
		-webkit-column-count: 3;
		-webkit-column-gap: 15px;
		-moz-column-count: 3;
		-moz-column-gap: 15px;
		column-count: 3;
		column-gap: 15px;
	}

	.document-preview .modal-dialog {
		width: 1100px;
	}

}

#PaymentDialogueEditForm .input {
	margin-bottom: 12px;
}

/* Exports */

.export-margin{
	margin:20px 0px;
}

.date-element{
	margin: 15px;
}


select.date-element{
	border-radius: 0;
	border-radius: 4px;
	border-color: #ccc;
	padding: 10px 10px;
	margin: 15px;
}

.large-checkbox
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  padding: 0;
  margin-top:8px !important;
}




.btn-delete-field {
	height: 34px;
}

.btn-add-type {
	margin-bottom: 15px;
}

.label-type{
	padding-left: 30px;
	padding-right: 0;
}


.options-margin{
	margin-bottom: 30px;
}


#datasheet-checkbox{
	width: 15px;
	float: right;
	margin: 0;
}

.hidden-fieldset {
    display: none;
}

.label-option{
	margin-top: 20px;
	padding-left: 30px;
	padding-right: 0;
	margin-right: 15px;
}



.tags {
	float: left;
	border: 1px solid #ccc;
	padding: 4px;
	font-family: Arial;
	border-radius: 5px;
	margin-top: 15px;
}

.tags span.tag {
	cursor: pointer;
	display: block;
	float: left;
	color: #555;
	background: #BDF;
	padding: 5px 10px;
	padding-right: 30px;
	margin: 4px;
}

.tagit-choice {
	background: #BDF;
	border-radius: 4px;
	border: 1px solid #CCC;
	margin-right: 5px;
	padding-left: 4px;
	padding-right: 6px;
}

.tags span.tag:hover {
	opacity: 0.7;
}

.tags span.tag:after {
	position: absolute;
	content: "×";
	border: 1px solid;
	border-radius: 10px;
	padding: 0 4px;
	margin: 3px 0 10px 7px;
	font-size: 10px;
}

.tags input {
	background: #eee;
	border: 0;
	margin: 4px;
	padding: 7px;
	width: auto;
}

.panel-heading-tickets{
	padding: 20px;
}


.card-options, .search-value{
    overflow: scroll;
    height: auto;
    max-height: 70%;
}

.search-value {
	max-height: 300px;
	padding-left: 30px;
	margin-bottom: 15px;
}

#statusTitle1{
	color: #d9534f;
}

#statusTitle2{
	color: #f0ad4e;
}

#statusTitle3, #statusTitle9{
	color: #5bc0de;
}

#statusTitle4{
	color: #337ab7;
}

#statusTitle5, #statusTitle6{
	color: #777;
}

#statusTitle7{
	color: #5cb85c;
}

#statusTitle8{
	color: #ab33b7;
}


.input-collapse1 {
	background-color: white;
	border: 1px solid #d9534f;
}
  
.input-collapse2 {
	background-color: white;
	border: 1px solid #f0ad4e;
}
  
.input-collapse3, .input-collapse9 {
	background-color: white;
	border: 1px solid #5bc0de;
}
  
.input-collapse4 {
	background-color: white;
	border: 1px solid #337ab7;
}
  
.input-collapse5, .input-collapse6{
	background-color: white;
	border: 1px solid #777;
}

.input-collapse7 {
	background-color: white;
	border: 1px solid #5cb85c;
}

.input-collapse8 {
	background-color: white;
	border: 1px solid #ab33b7;
}




.nb-tickets1{
	color: white;
	background-color: #d9534f;
	padding: .2em .6em .2em;
}
  
.nb-tickets2{
	color: white;
	background-color: #f0ad4e;
	padding: .2em .6em .2em;
}
  
.nb-tickets3, .nb-tickets9 {
	color: white;
	background-color: #5bc0de;
	padding: .2em .6em .2em;
}
  
.nb-tickets4{
	color: white;	
	background-color: #337ab7;
	padding: .2em .6em .2em;
}
  
.nb-tickets5, .nb-tickets6{
	color: white;	
	background-color: #777;
	padding: .2em .6em .2em;
}

.nb-tickets7{
	color: white;
	background-color: #5cb85c;
	padding: .2em .6em .2em;
}

.nb-tickets8{
	color: white;
	background-color: #ab33b7;
	padding: .2em .6em .2em;
}



.collapse-item-search{
	margin-left: 13px;
}



.search-value-parent{
	max-height: 500px;
}

.input-collapse-search{
	border: 1px solid lightgray;
}

.search-title{
	color: #337ab7;
}

.nb-objet-search{
	background-color: #337ab7;
}


#SearchIndexForm{
	margin-bottom: 40px;
}


.ticketsCollapse:hover, .ticketsCollapse:focus {
	text-decoration: none;
}


.search-collapse{
	padding-left: 30px;
}


#invoices{
	padding-left: 100px;
}


#TicketOption{
	margin: 5px 0 0 5px;
}


.ticket-option1{
	color: white;	
	background-color: #337ab7;
	padding: .2em .6em;
}

.ticket-option2{
	color: white;
	background-color: #d9534f;
	padding: .2em .6em .2em;
}

.ticket-option3{
	color: white;
	background-color: #d9534f;
	padding: .2em .6em .2em;
}




.ticket-ouvert-abonnes {
    width: 100%; 
    border-collapse: collapse; 
}

.ticket-ouvert-abonnes th,
.ticket-ouvert-abonnes td {
    border: 1px solid #dddddd; 
    text-align: left;
    padding: 8px; 
}

.ticket-ouvert-abonnes th {
    background-color: #333; 
    color: #f2f2f2; 
    text-align: center;
}

.ticket-ouvert-abonnes tbody tr:nth-child(even) {
    background-color: lightgray; /* Couleur de fond gris clair pour les lignes paires */
}

.load-table-abonnes {
    min-height: 400px; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
}
 

.scroll-table-abonne{
	overflow: scroll;
	height: 400px; 

}

.loader-table-abonnes {
	--d:22px;
	width: 4px;
	height: 4px;
	border-radius: 50%;
	color: gray;
	box-shadow: 
	  calc(1*var(--d))      calc(0*var(--d))     0 0,
	  calc(0.707*var(--d))  calc(0.707*var(--d)) 0 1px,
	  calc(0*var(--d))      calc(1*var(--d))     0 2px,
	  calc(-0.707*var(--d)) calc(0.707*var(--d)) 0 3px,
	  calc(-1*var(--d))     calc(0*var(--d))     0 4px,
	  calc(-0.707*var(--d)) calc(-0.707*var(--d))0 5px,
	  calc(0*var(--d))      calc(-1*var(--d))    0 6px;
	animation: l27 1s infinite steps(8);
  }
  @keyframes l27 {
	100% {transform: rotate(1turn)}
  }

  .acs{
	width: 100%;
  }


  .bouton-container-metrics {
	display: flex;
	justify-content: space-between;
	margin: 20px;
}

.bouton-container-metrics button {
	background-color: hsla(210, 75%, 60%, 0.7); 
	border: none;
	border-radius: 5px;
	padding: 10px 20px;
	margin: 0 10px;
	cursor: pointer;
	font-size: 16px;
	transition: background-color 0.3s ease, transform 0.2s ease;
}

.bouton-container-metrics button:hover {
	background-color: hsla(210, 81%, 46%, 0.7); 
	transform: scale(1.05);
}

.bouton-container-metrics button:focus {
	outline: none;
	box-shadow: 0 0 5px rgba(179, 224, 240, 0.5); 
}


#bouton-metrics-disabled {
    background-color: #ccc; 
    color: #666; 
    cursor: not-allowed; 
    pointer-events: none; 
}


.preview-image {
	object-fit: cover;
	width: 200px;
	height: 200px;
	margin: 10px;
	border: 1px solid #ddd;
	cursor: pointer;
  }
  
.panel-body.drag-over {
	background-color: #eee; /* Change the background color on drag over */
}

.panel-body > img{
	max-width: 100%;
}

.upload-button{
	margin: 5px 7px;
}


.search-loading {
    text-align: center;
    padding: 20px;
    display: none;
}

.search-loading .spinner {
    display: inline-block;
    width: 40px;
    height: 40px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
